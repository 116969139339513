<template>
    <layout-div>
        <div class="card">
                <div class="card-body">
{{content}}
                </div>
        </div>
    </layout-div>
</template>

<script>
 import LayoutDiv from '@/components/LayoutDiv.vue';
 import { gethome } from '@/api/Authentication.js'


 export default {
    name: 'home',
    components: {
     LayoutDiv
   },
   data() {
    return {
        content: ''
    };
   },
   created() {

        gethome().then(rep => {
				this.content = rep.data;
			});
   },
   methods: {

   }



}
</script>